<template>
    <div class="offer-wrapper uk-animation-fade">
        <!-- TODO: ecommerce layout for header wrapper sidebar ETC -->
        <ecommerce-hero
                class="uk-animation-fade"
                title="Master category header"
                :background="heroBackground">
        </ecommerce-hero>


        <div class="uk-container-large uk-margin-auto uk-padding-small uk-animation-fade">
            <ul class="uk-breadcrumb">
                <li><a href="#shop">Shop</a></li>
                <li class="uk-disabled"><a>Offers</a></li>
            </ul>
        </div>

        <div class="uk-container-large uk-margin-auto uk-margin-large-bottom uk-padding-small">
            <div class="ecommerce category category-master">

             // master category
            </div>
        </div>

    </div>


</template>

<script>

    import asyncOperations from "@/client/extensions/composition/asyncOperations";
    import ecommerceHero from "@/client/components/ecommerce/Hero.vue";

    export default {
        props: {
            categoryAlias: {
                type: [String, Boolean],
                default: 'devices'
            },
            heroBackground: {
                type: [String],
                default: 'ecommerce/headers/sample_header_1.jpg'
            }
        },
        components: {
            ecommerceHero
        },
        data: function () {
            return {
                packages: {
                    0 : {
                        title: 'Prestige 6h',
                        subTitle: '<strong>Something to highlight</strong> with this package and 24 month commitment',
                        dataMethod: 'FLAT RATE 4G',
                        limitText: '8Go',
                        commitmentTime: 12,
                        includes: [
                            'Unlimited calls, SMS / MMS ',
                            '8GO in French Polynesia'
                        ],
                        benefits: [
                            {
                                'link': '#',
                                'label': 'Some other benefits of package'
                            }
                        ],
                        pricing: {
                            0 : {
                                period: 12,
                                price: 150
                            },
                            1: {
                                period: 24,
                                price: 199,
                            }
                        }
                    },
                    1 : {
                        title: 'Prestige 8h',
                        subTitle: '<strong>Something else to highlight</strong> some other text',
                        dataMethod: 'FLAT RATE 4G & 5G',
                        limitText: '22Go',
                        commitmentTime: 12,
                        includes: [
                            'Unlimited calls, SMS / MMS ',
                            '22GO in French Polynesia'
                        ],
                        benefits: [
                            {
                                'link': '#',
                                'label': 'Some benefit of package'
                            }
                        ],
                        pricing: {
                            0 : {
                                period: 12,
                                price: 900
                            },
                            1: {
                                period: 24,
                                price: '1 200',
                            }
                        }
                    },
                    2 : {
                        title: 'Prestige 12h',
                        subTitle: '<strong>Something to highlight</strong> with this package and 24 month commitment',
                        dataMethod: 'FLAT RATE 4G',
                        limitText: '10Go',
                        commitmentTime: 12,
                        includes: [
                            'Unlimited calls, SMS / MMS ',
                            '10GO in French Polynesia'
                        ],
                        benefits: [
                            {
                                'link': '#',
                                'label': 'Some benefit of package'
                            }
                        ],
                        pricing: {
                            0 : {
                                period: 12,
                                price: 99
                            },
                            1: {
                                period: 24,
                                price: 350,
                            }
                        }
                    },
                    3 : {
                        title: 'Prestige 8 p.m.',
                        subTitle: '<strong>Highlight</strong> Text for the highlight',
                        dataMethod: 'FLAT RATE 4G',
                        limitText: '1Go',
                        commitmentTime: 12,
                        includes: [
                            'Unlimited calls, SMS / MMS ',
                            '1GO in French Polynesia'
                        ],
                        benefits: [
                            {
                                'link': '#',
                                'label': 'Some Special Benefit'
                            }
                        ],
                        pricing: {
                            0 : {
                                period: 12,
                                price: 375
                            },
                            1: {
                                period: 24,
                                price: 499,
                            }
                        }
                    },
                },
            };
        },
        computed: {


        },
        methods: {

        },
        mounted () {

        },
    }
</script>

<style scoped lang="scss">

    .package-box {
        .package-summary {
            .package-limit{
                font-size: calc(var(--global-font-size) * 2.4);

            }
            .content {
                margin-bottom: calc(var(--global-margin) * 2);
            }
        }
        .package-info-separator {
            margin-top: calc(var(--global-margin) * 1.5);
            margin-bottom: calc(var(--global-margin) * 1.5);

        }
    }

</style>
